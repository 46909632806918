import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "../../store";
import calculateTrialDaysRemaining from "../../utils/calculateTrialDaysRemaining";

interface OrganizationTierProps {
  trialColor?: string;
  premiumColor?: string;
}

const OrganizationTier = ({
  premiumColor,
  trialColor,
}: OrganizationTierProps) => {
  const currentOrganization = useSelector(
    (state) => state.user.currentOrganization,
  );

  const [trialDaysRemaining, setTrialDaysRemaining] = useState(-1);

  useEffect(() => {
    //first run on load or when org changes
    if (
      currentOrganization?.adminSettings?.tier === "TRIAL" &&
      currentOrganization?.adminSettings?.trialEndDate
    ) {
      const days = calculateTrialDaysRemaining(currentOrganization);
      setTrialDaysRemaining(days);
    }

    //every hour after
    const interval = setInterval(() => {
      const days = calculateTrialDaysRemaining(currentOrganization);
      setTrialDaysRemaining(days);
    }, 3600000);
    return () => {
      clearInterval(interval);
    };
  }, [currentOrganization]);

  return (
    <>
      {currentOrganization?.adminSettings?.tier === "PREMIUM" && (
        <Typography
          sx={{ fontStyle: "italic" }}
          style={{
            marginTop: -20,
            marginLeft: 10,
            color: premiumColor || "#FFD700",
          }}
        >
          Premium
        </Typography>
      )}
      {currentOrganization?.adminSettings?.tier === "TRIAL" && (
        <Typography
          sx={{ fontStyle: "italic" }}
          style={{
            marginTop: trialDaysRemaining > -1 ? -20 : 0,
            marginLeft: 10,
            color: trialColor || "#37fd12",
          }}
        >
          {trialDaysRemaining > -1 && (
            <>Trial - {trialDaysRemaining} days left.</>
          )}
          {trialDaysRemaining < 0 && <> - Your trial has ended.</>}
        </Typography>
      )}
    </>
  );
};

export default OrganizationTier;
