/* eslint-disable no-restricted-properties */

import { daysBetween } from "rrule/dist/esm/dateutil";
import { Organization } from "../types/organization";

const calculateTrialDaysRemaining = (currentOrganization: Organization) => {
  const trialEndDate = new Date(
    currentOrganization?.adminSettings?.trialEndDate,
  );
  if (trialEndDate) {
    return daysBetween(trialEndDate, new Date());
  }
};

export default calculateTrialDaysRemaining;
